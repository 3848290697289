import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { NavBarDropdown } from './widgets'

export default ({ locale = 'en-us', children, navBarId, footerId, pageContext }) => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 93) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      allPrismicHomepageBodyNavigationBar {
        nodes {
          primary {
            cta_text {
              text
            }
            resources_title {
              text
            }
          }
          id
        }
      }
      allPrismicHomepageBodyFooter {
        nodes {
          primary {
            copyright_notice {
              text
            }
            privacy_policy {
              text
            }
            terms_of_use {
              text
            }
          }
          id
        }
      }
      allPrismicResource(
        sort: {
          fields: [first_publication_date]
          order: ASC
        }
      ) {
        edges {
          node {
            lang
            uid
            data {
              title {
                html
                text
              }
            }
          }
        }
      }
    }
  `)

  const navigationBar = data.allPrismicHomepageBodyNavigationBar.nodes.find(cur => cur.id === navBarId).primary
  const footer = data.allPrismicHomepageBodyFooter.nodes.find(cur => cur.id === footerId).primary
  const resourceItems = data.allPrismicResource.edges.filter(cur => cur.node.lang === locale).map(cur => ({text: cur.node.data.title.text, href: pageContext.links[locale].resources + '/' + cur.node.uid + '/'}))
  return (
    <div>
      <nav className='navbar is-fixed-top has-shadow'>
        <div className='container'>
          <div className='navbar-brand'>
            <a className='navbar-item' href={pageContext.links[locale].home}><Img fixed={data.logo.childImageSharp.fixed} quality={99}/></a>
            <span className='navbar-burger burger' data-target='navMenu' onClick={e => {
              e.target.classList.toggle('is-active')
              const nav = document.querySelector('#navMenu')
              nav.classList.toggle('is-active')
            }}><span></span><span></span><span></span></span>
          </div>
          <div className='navbar-menu' id='navMenu'>
            <div className='navbar-end'>
              <NavBarDropdown trigger={navigationBar.resources_title.text} href={pageContext.links[locale].resources + '/'} items={resourceItems} />
              {/*<NavBarDropdown trigger={<div><span className="icon is-small m-r-sm"><i className="fa fa-globe"></i></span><span>{locale === 'es-es' ? 'Español' : 'English'}</span></div>} items={[{text: 'English', href: pageContext.links['en-us'].home}, {text: 'Español', href: pageContext.links['es-es'].home}]} />*/}
              <span className='navbar-item'>
                <a className='button is-primary is-outlined' href='/websurveys'>
                  <span>{navigationBar.cta_text.text}</span>
                </a>
            </span>
            </div>
          </div>
        </div>
      </nav>
      {children}
      <footer className='footer'>
        <div className='content has-text-centered'>
          <p>
            <strong>NPS.io</strong>
          </p>
        </div>
        <div className='content has-text-centered'>
          <p>
            <a href='/npsio-privacy-policy.pdf'>{footer.privacy_policy.text}</a>
            &nbsp;|&nbsp;
            <a href='https://app.termly.io/document/terms-and-conditions/f767951f-dec7-48b9-89ba-c2887fe79e29'>{footer.terms_of_use.text}</a>
          </p>
        </div>
        <div className='content has-text-centered'>
          <p>
            <span className='is-size-7'>{footer.copyright_notice.text}</span>
          </p>
        </div>
      </footer>
    </div>
  )
}
