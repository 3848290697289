import { Link } from 'gatsby'
import React from 'react'

const NavBarDropdown = ({trigger, href, items = []}) => (
  <div className="navbar-item has-dropdown is-hoverable">
    {href ?
      <a className="navbar-link is-arrowless" href={href}>
        {trigger}
      </a> :
      <span className="navbar-link is-arrowless" >
        {trigger}
      </span>}

    <div className="navbar-dropdown">
      {items.map((cur, idx) => (
        <Link key={idx} className="navbar-item" to={cur.href}>
          {cur.text}
        </Link>
      ))}
    </div>
  </div>
)

export { NavBarDropdown }
